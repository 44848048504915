import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import iconCheckGreen from "../images/icon-check-green.svg"
import iconHouse from "../images/icon-house.svg"
import iconApartment from "../images/icon-apartment.svg"

import elektrimuujadEestienergia from "../images/elektrimuujad-eestienergia.svg"
import elektrimuujad220energia from "../images/elektrimuujad-220energia.svg"
import elektrimuujadElektrum from "../images/elektrimuujad-elektrum.png"
import elektrimuujadEestigaas from "../images/elektrimuujad-eestigaas.svg"
import elektrimuujadVkg from "../images/elektrimuujad-vkg.png"
import elektrimuujadAlexela from "../images/elektrimuujad-alexela.svg"

import soojuspumpEmotsioon from "../images/soojuspump-emotsioon.jpg"
import borsihinnasoovitaja from "../images/borsihinnasoovitja.png"

import newsimage1 from "../images/newsimage-1.jpg"
import newsimage2 from "../images/newsimage-2.jpg"
import newsimage3 from "../images/newsimage-3.jpg"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <>
      <div className=" bg-gray-50">
        <div className="max-w-screen-xl mx-auto flex flex-col sm:flex-row sm:space-x-6 px-4 pb-12 pt-8 sm:py-24 sm:px-8">
          <div className="sm:basis-3/5">
            <h1 className="text-2xl font-semibold sm:text-4xl text-vordle-blue-800">
              Võrdle ja leia oma vajadustele parim elektripakett!
            </h1>

            <ul className="flex flex-col list-none space-y-2 text-base sm:text-lg text-vordle-blue-800 mt-6 mb-8 sm:my-8">
              {heroBulletsData.map((data, index) => (
                <li
                  key={index}
                  className="flex items-baseline space-x-1.5 sm:space-x-2.5"
                >
                  <img src={iconCheckGreen} className="h-3 sm:h-4" alt="" />
                  <p>{data.text}</p>
                </li>
              ))}
            </ul>
          </div>

          {/* Alusta siit */}
          <div className="sm:basis-2/5 bg-white shadow-lg shadow-gray-200 rounded p-6 sm:p-12">
            <p className="text-base sm:text-lg text-vordle-blue-800 font-semibold">
              Alusta siit, vali elukoht:
            </p>

            <div className="flex space-x-2 mt-4 sm:mt-6">
              <button className="basis-1/2 flex items-center justify-center space-x-2 rounded font-semibold text-vordle-blue-800 border-2 border-vordle-blue-800 py-4 sm:py-6 ">
                <img src={iconApartment} className="h-6 sm:h-8" alt="" />
                <span>Korter</span>
              </button>

              <button className="basis-1/2 flex items-center justify-center space-x-2 rounded font-semibold text-vordle-blue-800 border border-gray-200 py-4 sm:py-6 ">
                <img src={iconHouse} className="h-6 sm:h-8" alt="" />
                <span>Maja</span>
              </button>
            </div>
            <button className="w-full bg-vordle-green-500 rounded-full font-semibold sm:text-lg text-white p-4 mt-4 sm:mt-6">
              Võrdle elektrihindu
            </button>
          </div>
          {/* //Alusta siit */}
        </div>
      </div>

      {/* Elektrimüüjad */}
      <div className="bg-gray-100">
        <div className="max-w-screen-xl mx-auto grid grid-cols-3 gap-y-6 gap-x-6 justify-items-center items-center sm:grid-cols-none sm:flex sm:flex-row sm:justify-between sm:space-x-4 py-12 sm:py-12 px-4 sm:px-8">
          <a href="#eestienergia">
            <img
              src={elektrimuujadEestienergia}
              alt="Eesti Energia"
              className="h-8"
            />
            <span className="absolute overflow-hidden -indent-96">
              Eesti Energia
            </span>
          </a>

          <a href="#220energia">
            <img
              src={elektrimuujad220energia}
              alt="220 Energia"
              className="h-8"
            />
            <div className="absolute overflow-hidden -indent-96">
              220 Energia
            </div>
          </a>

          <a href="#220energia">
            <img
              src={elektrimuujadElektrum}
              alt="Elektrum"
              className="h-auto w-[106px]"
            />
            <div className="absolute overflow-hidden -indent-96">Elektrum</div>
          </a>

          <a href="#eestigaas">
            <img
              src={elektrimuujadEestigaas}
              alt="Eesti Gaas"
              className="h-8"
            />
            <div className="absolute overflow-hidden -indent-96">
              Eesti Gaas
            </div>
          </a>

          <a href="#Vkg">
            <img src={elektrimuujadVkg} alt="Vkg" className="h-auto w-[76px]" />
            <div className="absolute overflow-hidden -indent-96">Vkg</div>
          </a>

          <a href="#alexela">
            <img src={elektrimuujadAlexela} alt="Alexela" className="h-8" />
            <div className="absolute overflow-hidden -indent-96">Alexela</div>
          </a>
        </div>
      </div>
      {/* //Elektrimüüjad */}

      {/* Juhend */}
      <div className="max-w-screen-xl mx-auto py-12 sm:py-36 px-4 sm:px-8">
        <h2 className="text-2xl sm:text-4xl font-semibold sm:text-center text-vordle-blue-800">
          Elektripaketi leidmine on{" "}
          <span className="text-vordle-green-600">kiire ja mugav</span>
        </h2>

        <div className="relative">
          <div className="absolute bg-vordle-green-500 sm:inset-x-0 -left-px sm:top-[3px] h-full sm:w-2/3 sm:mx-auto w-0.5 sm:h-0.5 ml-7 sm:mt-12 -z-50"></div>
          <div className="flex flex-col sm:flex-row mt-8 sm:mt-24 space-y-6 sm:space-y-0 sm:space-x-24">
            {/* 1 */}
            <div className="sm:basis-1/3 flex flex-row sm:flex-col items-center space-x-4">
              <div className="rounded-full bg-vordle-blue-800 border-4 sm:border-16 border-white h-14 sm:h-26 w-14 sm:w-26 flex justify-center items-center shrink-0">
                <div className="text-white font-medium text-xl sm:text-3xl -mt-px">
                  1
                </div>
              </div>
              <div className="sm:mt-12 sm:text-lg text-vordle-blue-800 sm:text-center">
                Sisesta oma <strong>tarbimisandmed</strong>.
              </div>
            </div>
            {/* //1 */}

            {/* 2 */}
            <div className="sm:basis-1/3 flex flex-row sm:flex-col items-center space-x-4">
              <div className="rounded-full bg-vordle-blue-800 border-4 sm:border-16 border-white h-14 sm:h-26 w-14 sm:w-26 flex justify-center items-center shrink-0">
                <div className="text-white font-medium text-xl sm:text-3xl -mt-px">
                  2
                </div>
              </div>
              <div className="sm:mt-12 sm:text-lg text-vordle-blue-800 sm:text-center">
                <strong>Võrdle ja leia</strong> oma vajadustele vastav
                elektripakett.
              </div>
            </div>
            {/* //2 */}

            {/* 3 */}
            <div className="sm:basis-1/3 flex flex-row sm:flex-col items-center space-x-4">
              <div className="rounded-full bg-vordle-blue-800 border-4 sm:border-16 border-white h-14 sm:h-26 w-14 sm:w-26 flex justify-center items-center shrink-0">
                <div className="text-white font-medium text-xl sm:text-3xl -mt-px">
                  3
                </div>
              </div>
              <div className="sm:mt-12 sm:text-lg text-vordle-blue-800 sm:text-center">
                Sõlmi müüjaga <strong>leping</strong>. Sinu teenus ei katke
                hetkeski.
              </div>
            </div>
            {/* //3 */}
          </div>
        </div>
        <div className="text-center">
          <button className="bg-vordle-green-500 rounded-full font-semibold sm:text-lg text-white w-full sm:w-auto px-8 py-4 sm:px-12 mt-8 sm:mt-18">
            Võrdle elektripakette
          </button>
        </div>
      </div>
      {/* //Juhend */}

      {/* Börsihinna soovitaja */}
      <div className=" bg-gray-50">
        <div className="max-w-screen-xl mx-auto px-4 pt-12 sm:pt-36 sm:px-8">
          <div className="flex flex-col sm:flex-row shadow-lg rounded overflow-hidden">
            <div className="basis-1/2 bg-vordle-blue-810">
              <img src={borsihinnasoovitaja} alt="" className="" />
            </div>
            <div className="basis-1/2 bg-vordle-blue-800 px-4 py-8 sm:p-12">
              <h2 className="text-lg sm:text-2xl font-semibold text-white">
                Elektri börsihind hetkel on 37.43 s/kWh, 6 h pärast tarbides
                säästaksid praegusega võrreldes 20%.
              </h2>
              <p className="text-white mt-6">
                Börsihinna tarbimissoovitajaga saad jälgida elektri börsihinda
                reaalajas ja näha elektri hinda täna ja homme tundide kaupa.
                Planeeri oma elektri tarbimist ning säästa raha.
              </p>

              <button className="bg-vordle-green-500 rounded-full font-semibold text-white px-12 py-4 mt-8 sm:mt-8 sm:float-right">
                Vaata lähemalt
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* //Börsihinna soovitaja */}

      {/* Soojuspumbad */}
      <div className=" bg-gray-50">
        <div className="max-w-screen-xl mx-auto px-4 pt-8 sm:pt-24 pb-12 sm:pb-36 sm:px-8">
          <div className="flex flex-col sm:flex-row shadow-lg rounded overflow-hidden">
            <div className="basis-1/2">
              <img src={soojuspumpEmotsioon} alt="" className="" />
            </div>
            <div className="basis-1/2 bg-white px-4 py-8 sm:p-12">
              <h2 className="text-lg sm:text-2xl font-semibold text-vordle-green-600">
                Millist soojuspumpa valida ja palju see maksma läheb?
              </h2>
              <p className="text-vordle-blue-800 mt-6">
                Leiame sulle ühe päringuga parimad soojuspumpade pakkumised
                kogemustega ettevõtetelt. Võrdle pakkumisi ja vali endale
                sobivaim.
              </p>
              <ul className="flex flex-col list-none space-y-2 text-vordle-blue-800 mt-6 sm:mt-8">
                {soojuspumbadBulletsData.map((data, index) => (
                  <li
                    key={index}
                    className="flex items-baseline space-x-1.5 sm:space-x-2.5"
                  >
                    <img src={iconCheckGreen} className="h-3 sm:h-4" alt="" />
                    <p>{data.text}</p>
                  </li>
                ))}
              </ul>
              <button className="bg-vordle-green-500 rounded-full font-semibold text-white px-12 py-4 mt-8 sm:mt-8 sm:float-right">
                Vaata lähemalt
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* //Soojuspumbad */}

      {/* Uudised */}
      <div className="max-w-screen-xl mx-auto px-4 py-12 sm:py-36 sm:px-8">
        <h2 className="text-2xl sm:text-4xl font-semibold sm:text-center text-vordle-blue-800">
          Blogi ja uudised
        </h2>

        <div className="mt-8 sm:mt-24">
          {newsData.map((data, index) => (
            <a
              href="#news"
              key={index}
              className="bg-gray-50 rounded flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-12 p-4 sm:p-12 mb-4 sm:mb-6"
            >
              <div className="w-full sm:w-72 shrink-0">
                <img src={data.image} className="rounded" alt="" />
              </div>
              <div className="">
                <p className="text-gray-400 text-sm mb-1 sm:mb-4">
                  {data.date}
                </p>
                <p className="text-vordle-green-600 text-base font-semibold sm:text-2xl">
                  {data.title}
                </p>
              </div>
            </a>
          ))}
        </div>
        <div className="sm:text-center">
          <button className="bg-vordle-green-500 rounded-full font-semibold sm:text-lg text-white px-8 py-4 sm:px-12 mt-8 sm:mt-12">
            Kõik uudised
          </button>
        </div>
      </div>
      {/* //Uudised */}

      {/* Footer */}

      {/* //Footer */}
    </>
  </Layout>
)

export default IndexPage

const heroBulletsData = [
  { text: "Võrdleme kõiki elektrituru pakkujaid" },
  { text: "Vaheta pakette või teenusepakkujaid kiirelt ja mugavalt" },
  { text: "Saavuta kokkuhoid 5 minutiga" },
  { text: "100% tasuta ja kohustuste vaba päring" },
]

const soojuspumbadBulletsData = [
  { text: "Võidad ajas, kütte- ja elektrikuludes." },
  { text: "Valikus tuntud kaubamärgid." },
  { text: "Kvaliteetne paigaldusteenus garanteeritud." },
  { text: "Personaalsed pakkumised 2 päevaga." },
  { text: "Leia sulle sobivaim hind." },
  { text: "Mugav täislahendus sinu kodule. " },
]

const newsData = [
  {
    date: "20. detsember 2021",
    title:
      "2021 aasta septembriga võrreldes on fikseeritud hinnaga elektri- paketid kallinenud ligi 35%, 2020. septembriga võrreldes ligi 145% ja pealkiri kolmel real",
    image: [newsimage1],
  },
  {
    date: "14. detsember 2021",
    title: "Gaasiturg 2021 ülevaade: aastaga on gaas kallinenud 354%",
    image: [newsimage2],
  },
  {
    date: "02. detsember 2021",
    title:
      "Elektri ning gaasi turuhindade võrdlus 2021 – elektrimüüjate hinnad erinevad kuni 22% ning gaasimüüjate hinnad kuni 32%",
    image: [newsimage3],
  },
]
